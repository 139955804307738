import React, { useState } from "react";
import {
  ArrowLeftOnRectangleIcon,
  BanknotesIcon,
  BuildingOfficeIcon,
  CalendarIcon,
  CircleStackIcon,
  ClipboardDocumentListIcon,
  HomeIcon,
  PaintBrushIcon,
  PresentationChartLineIcon,
  SpeakerWaveIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

const menuItems = [
  {
    name: "Home",
    icon: <HomeIcon className="w-5 h-5" />,
    image: "assets/webScreenshots/home.png",
  },
  {
    name: "Offices",
    icon: <BuildingOfficeIcon className="w-5 h-5" />,
    image: "assets/webScreenshots/loader.png",
  },
  {
    name: "Roles",
    icon: <UsersIcon className="w-5 h-5" />,
    image: "assets/webScreenshots/loader.png",
  },
  {
    name: "Reps",
    icon: <UserGroupIcon className="w-5 h-5" />,
    image: "assets/webScreenshots/loader.png",
  },
  {
    name: "Onboarding",
    icon: <ClipboardDocumentListIcon className="w-5 h-5" />,
    image: "assets/webScreenshots/onboarding.png",
  },
  {
    name: "Announcement",
    icon: <SpeakerWaveIcon className="w-5 h-5" />,
    image: "assets/webScreenshots/loader.png",
  },
  {
    name: "Appointments",
    icon: <CalendarIcon className="w-5 h-5" />,
    image: "assets/webScreenshots/loader.png",
  },
  {
    name: "Track projects",
    icon: <PresentationChartLineIcon className="w-5 h-5" />,
    image: "assets/webScreenshots/loader.png",
  },
  {
    name: "Customize App",
    icon: <PaintBrushIcon className="w-5 h-5" />,
    image: "assets/webScreenshots/customization.png",
  },
  {
    name: "Plans",
    icon: <BanknotesIcon className="w-5 h-5" />,
    image: "assets/webScreenshots/pricing.png",
  },
  {
    name: "Credits",
    icon: <CircleStackIcon className="w-5 h-5" />,
    image: "assets/webScreenshots/loader.png",
  },
  {
    name: "Org tree",
    icon: <UserGroupIcon className="w-5 h-5" />,
    image: "assets/webScreenshots/loader.png",
  },
];

const profileItems = [
  {
    name: "Profile",
    icon: <UserIcon className="w-5 h-5" />,
    image: "/webScreenshots/loader.png",
  },
  {
    name: "Logout",
    icon: <ArrowLeftOnRectangleIcon className="w-5 h-5" />,
    image: "/webScreenshots/loader.png",
  },
];

const FeaturePage = () => {
  const [selectedImage, setSelectedImage] = useState(
    "assets/webScreenshots/home.png"
  );

  const handleMenuClick = (image) => {
    setSelectedImage(image);
  };

  const clickableItems = ["Home", "Onboarding", "Customize App", "Plans"];

  return (
    <div className="flex h-full">
      {/* Sidebar */}
      <aside className="w-64 bg-gray-50 dark:bg-gray-800 text-white p-4 h-full">
        <div className="h-full px-3 py-4 overflow-y-auto">
          {/* Logo */}
          <div className="flex justify-center mb-5">
            <img
              className="h-12 w-auto sm:h-14"
              src="/logo.png"
              alt="Logo"
              style={{
                filter: "drop-shadow(0 0 5px rgba(255, 255, 255, 0.8))",
              }}
            />
          </div>

          <hr className="border-gray-200 dark:border-gray-700 mb-4" />

          {/* Menu Options */}
          <ul className="space-y-2 font-medium text-sm">
            {menuItems.map((item) => (
              <li key={item.name}>
                <button
                  onClick={() =>
                    clickableItems.includes(item.name) &&
                    handleMenuClick(item.image)
                  }
                  className={`flex items-center w-full p-2 rounded-lg group ${
                    clickableItems.includes(item.name)
                      ? "text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                      : "text-gray-500 dark:text-gray-400 cursor-default"
                  }`}
                >
                  {item.icon}
                  <span className="ms-3">{item.name}</span>
                </button>
              </li>
            ))}
          </ul>

          <hr className="border-gray-200 dark:border-gray-700 my-4" />

          {/* Profile and Logout */}
          <ul className="space-y-2 font-medium text-sm">
            {profileItems.map((item) => (
              <li key={item.name}>
                <button
                  onClick={() =>
                    clickableItems.includes(item.name) &&
                    handleMenuClick(item.image)
                  }
                  className={`flex items-center w-full p-2 rounded-lg group ${
                    clickableItems.includes(item.name)
                      ? "text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                      : "text-gray-500 dark:text-gray-400 cursor-default"
                  }`}
                >
                  {item.icon}
                  <span className="ms-3">{item.name}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </aside>

      {/* Main Content */}
      <main className="flex-1 bg-gray-800 flex justify-center items-center">
        <img
          src={selectedImage}
          alt="Selected"
          className="max-w-full max-h-full"
        />
      </main>
    </div>
  );
};

export default FeaturePage;
