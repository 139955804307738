import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import { Button } from "../Button";
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle,
} from "../Dialog";

// Redux
import { setShowErrorModal } from "../../redux/actions";

const ErrorModal = () => {
  const dispatch = useDispatch();

  const showErrorModal = useSelector(
    ({ mainReducer }) => mainReducer.showErrorModal
  );
  const errorModalProps =
    useSelector(({ mainReducer }) => mainReducer.errorModalProps) || {};

  const {
    title = null,
    message = null,
    children = null,
    actionButtonTitle = null,
    actionButtonGoto = null,
  } = errorModalProps;

  const onCloseModal = useCallback(() => {
    dispatch(setShowErrorModal(false, {}));
  }, []);

  return (
    <Dialog open={showErrorModal} onClose={onCloseModal}>
      {/* TODO: Add ICON */}
      <DialogTitle>{title}</DialogTitle>
      <DialogDescription>{message}</DialogDescription>
      {children}
      <DialogActions>
        <Button plain onClick={onCloseModal}>
          Cancel
        </Button>
        {!!actionButtonTitle && (
          <Button onClick={actionButtonGoto}>{actionButtonTitle}</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ErrorModal;
