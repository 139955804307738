import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

// Styles
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

// Context
import { AppProvider } from "./contexts/AppContext";

// Components
import Router from "./routes";

// Redux
import { store, persistor } from "./redux/store";

function App() {
  return (
    <AppProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer />
          <Router />
        </PersistGate>
      </Provider>
    </AppProvider>
  );
}

export default App;
