import React from "react";
import Header from "../../components/Header"; 
import Contactus from "../Dashboard/components/Contactus";

const ContactUsPage = () => {
  return (
    <div className="bg-white">
      <Header />
      <main>
        <Contactus />
      </main>
    </div>
  );
};

export default ContactUsPage;