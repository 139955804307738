import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Component
import Header from "../../components/Header";

// API
import { makePayment } from "../../utils/ApiUtils";

// Redux
import { setShowErrorModal } from "../../redux/actions";

const INITIAL_FORM = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  companyName: "",
  amount: "",
};

const INITIAL_ERRORS = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  companyName: "",
  amount: "",
};

export default function Payment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    amount: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    amount: "",
  });

  const handleCancelPayment = () => {
    navigate("/");
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!formData.firstName) {
      setErrors({
        ...INITIAL_ERRORS,
        firstName: "First name is required",
      });
      setIsLoading(false);
      return;
    }

    if (!formData.lastName) {
      setErrors({
        ...INITIAL_ERRORS,
        lastName: "Last name is required",
      });
      setIsLoading(false);
      return;
    }

    if (!formData.email) {
      setErrors({
        ...INITIAL_ERRORS,
        email: "Email is required",
      });
      setIsLoading(false);
      return;
    }

    if (!formData.phone) {
      setErrors({
        ...INITIAL_ERRORS,
        phone: "Phone number is required",
      });
      setIsLoading(false);
      return;
    }

    if (!formData.companyName) {
      setErrors({
        ...INITIAL_ERRORS,
        companyName: "Company name is required",
      });
      setIsLoading(false);
      return;
    }

    let amount = formData.amount;
    if (
      !amount ||
      Number(amount) < 1 ||
      Number(amount) > 2000 ||
      isNaN(Number(amount))
    ) {
      setErrors({
        ...INITIAL_ERRORS,
        amount: "Amount is invalid",
      });
      setIsLoading(false);
      return;
    }

    const dataToSend = { ...formData };
    dataToSend["amount"] = String(Number(amount) * 100);
    dataToSend["redirectUrl"] = window.location.host.includes("localhost")
      ? `${location.protocol}//${location.host}`
      : "https://www.knockproapp.com";
    try {
      const response = await makePayment(dataToSend);
      window.location.href = response.url;
    } catch (error) {
      setIsLoading(false);
      const errorModalProps = {
        title: "Error",
        message: "We failed to process your payment. Please try again!",
        children: null,
      };
      dispatch(setShowErrorModal(true, errorModalProps));
      setFormData(INITIAL_FORM);
    }
  };

  return (
    <div className="bg-white">
      <Header showHome={true} />
      <div className="bg-white min-h-screen flex justify-center items-center pt-24 sm:pt-60 lg:pt-32 max-w-md w-full mx-auto">
        <div className="bg-gray-100 p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4 text-center">Payment</h2>
          <div className="space-y-10 divide-y divide-gray-900/10">
            <form
              className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
              onSubmit={handleSubmit}
            >
              <div className="px-4 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      First name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                          errors.firstName ? "ring-red-500" : "ring-gray-300"
                        } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2`}
                      />
                      {errors.firstName && (
                        <span className="text-red-500 mt-1 text-xs">
                          {errors.firstName}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Last name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                          errors.lastName ? "ring-red-500" : "ring-gray-300"
                        } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2`}
                      />
                      {errors.lastName && (
                        <span className="text-red-500 mt-1 text-xs">
                          {errors.lastName}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-full">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                          errors.email ? "ring-red-500" : "ring-gray-300"
                        } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2`}
                      />
                      {errors.email && (
                        <span className="text-red-500 mt-1 text-xs">
                          {errors.email}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-full">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone Number
                    </label>
                    <div className="mt-2">
                      <input
                        id="phone"
                        name="phone"
                        type="tel"
                        value={formData.phone}
                        onChange={handleChange}
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                          errors.phone ? "ring-red-500" : "ring-gray-300"
                        } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2`}
                      />
                      {errors.phone && (
                        <span className="text-red-500 mt-1 text-xs">
                          {errors.phone}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="street-address"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Company Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="companyName"
                        id="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                          errors.companyName ? "ring-red-500" : "ring-gray-300"
                        } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2`}
                      />
                      {errors.companyName && (
                        <span className="text-red-500 mt-1 text-xs">
                          {errors.companyName}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="street-address"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Amount
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        name="amount"
                        id="amount"
                        value={formData.amount}
                        onChange={handleChange}
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                          errors.amount ? "ring-red-500" : "ring-gray-300"
                        } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2`}
                      />
                      {!errors.amount && (
                        <span className="text-gray-500 mt-1 text-xs italic">
                          Maximum deposit allowed is $2000
                        </span>
                      )}
                      {errors.amount && (
                        <span className="text-red-500 mt-1 text-xs">
                          {errors.amount}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                <button
                  type="button"
                  onClick={handleCancelPayment}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center">
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <span className="ml-2">Loading...</span>
                    </div>
                  ) : (
                    "Make Payment"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
