import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Utils
import { getCompanyLogo, getProfilePic } from "../../utils/ApiUtils";

export default function Reps() {
  let { firstName, lastName, phone, email, companyId, imgPathname } =
    useParams();

  const [imageUrl, setImageUrl] = useState("/logo - 1.png");
  const [logoUrl, setLogoUrl] = useState("/logo - 1.png");
  const getAndSetProfilePicture = async () => {
    try {
      if (imgPathname === "NA") return;
      const { pictureUrl } = await getProfilePic({ imgPathname });
      setImageUrl(pictureUrl);
    } catch (err) {
      // Handle error
    }
  };

  const getLogo = async () => {
    try {
      if (companyId) {
        const company = await getCompanyLogo({ companyId });
        console.log(company);
        if (company?.logo) setLogoUrl(company.logo);
      }
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    if (imgPathname) {
      getAndSetProfilePicture();
    }
  }, [imgPathname]);

  useEffect(() => {
    if (companyId) {
      getLogo();
    }
  }, [companyId]);

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-900 py-10">
      <div className="container mx-auto px-4">
        <div
          key={firstName}
          className="relative rounded-2xl bg-gray-800 px-8 py-20 max-w-md mx-auto"
        >
          {/* Company logo */}
          <img
            src={logoUrl}
            alt="Knockpro Logo"
            onError={() => setLogoUrl("/logo - 1.png")}
            className="absolute top-4 left-4 h-16 w-auto"
          />
          {/* User's image */}
          <img
            className="mx-auto h-48 w-48 rounded-full md:h-56 md:w-56 mt-8"
            src={imageUrl}
            alt="User Profile"
            onError={() => setImageUrl("/logo - 1.png")}
          />
          <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-white text-center">
            {firstName} {lastName && lastName !== "NA" ? lastName : ""}
          </h3>

          <div className="flex flex-col mt-4 items-center">
            {phone && phone !== "NA" && (
              <a
                href={`tel:${phone}`}
                className="text-sm leading-6 text-gray-400 text-center hover:text-blue-500 transition-colors mb-2"
              >
                {phone}
              </a>
            )}
            {email && email !== "NA" && (
              <a
                href={`mailto:${email}`}
                className="text-sm leading-6 text-gray-400 text-center hover:text-blue-500 transition-colors"
              >
                {email}
              </a>
            )}
          </div>

          <ul role="list" className="mt-6 flex justify-center gap-x-6">
            {/* ... The rest of your icons and elements ... */}
          </ul>
        </div>
      </div>
    </div>
  );
}
