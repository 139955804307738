import React, { useState } from "react";
import { Dialog, DialogPanel, Radio, RadioGroup } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon as XMarkIconOutline,
} from "@heroicons/react/24/outline";
import {
  CheckIcon,
  XMarkIcon as XMarkIconMini,
} from "@heroicons/react/20/solid";

const pricing = {
  frequencies: [
    { value: "company", label: "Company", priceSuffix: "/month" },
    { value: "individual", label: "Individual", priceSuffix: "/month" },
  ],
  tiers: [
    {
      name: "Knockpro Lite",
      id: "tier-normal",
      href: "mailto:support@knockproapp.com?subject=Book a Demo&body=Hi Knockpro Team, I would like to book a demo for Knockpro Lite.",
      featured: false,
      description: "The essentials to provide your best work for clients.",
      price: { company: "$29.99", individual: "Free" },
      highlights: ["Canvassing"],
    },
    {
      name: "Knockpro Premium",
      id: "tier-premium",
      href: "mailto:support@knockproapp.com?subject=Book a Demo&body=Hi Knockpro Team, I would like to book a demo for Knockpro Premium.",
      featured: true,
      description: "A plan that enables effective communication.",
      price: { company: "$39.99", individual: "$19.99" },
      highlights: [
        "KPIs",
        "Canvassing",
        "Company Onboarding/Rep Docs",
        "Scheduling",
      ],
    },
    {
      name: "Knockpro Elite",
      id: "tier-Platinum",
      href: "mailto:support@knockproapp.com?subject=Book a Demo&body=Hi Knockpro Team, I would like to book a demo for Knockpro Elite.",
      featured: false,
      description: "A plan that enables users to scale faster",
      price: { company: "$49.99", individual: "N/A" },
      highlights: [
        "Teams",
        "Canvassing",
        "Company Onboarding/Rep Docs",
        "Scheduling",
        "Training Academy",
        "Messaging",
        "Pipeline Management",
      ],
    },
  ],
  sections: [
    {
      name: "Features",
      features: [
        {
          name: "Canvassing",
          tiers: {
            "Knockpro Lite": true,
            "Knockpro Premium": true,
            "Knockpro Elite": true,
          },
        },
        {
          name: "KPIs",
          tiers: {
            "Knockpro Lite": false,
            "Knockpro Premium": true,
            "Knockpro Elite": true,
          },
        },
        {
          name: "Company Onboarding/Rep Docs",
          tiers: {
            "Knockpro Lite": false,
            "Knockpro Premium": true,
            "Knockpro Elite": true,
          },
        },
        {
          name: "Scheduling",
          tiers: {
            "Knockpro Lite": false,
            "Knockpro Premium": true,
            "Knockpro Elite": true,
          },
        },
        {
          name: "Training Academy",
          tiers: {
            "Knockpro Lite": false,
            "Knockpro Premium": false,
            "Knockpro Elite": true,
          },
        },
        {
          name: "Messaging",
          tiers: {
            "Knockpro Lite": false,
            "Knockpro Premium": false,
            "Knockpro Elite": true,
          },
        },
        {
          name: "Pipeline Management",
          tiers: {
            "Knockpro Lite": false,
            "Knockpro Premium": false,
            "Knockpro Elite": true,
          },
        },
      ],
    },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Pricing = () => {
  const [frequency, setFrequency] = useState(pricing.frequencies[0]);

  const availableTiers = pricing.tiers.filter(
    (tier) => tier.price[frequency.value] !== "N/A"
  );
  const availableTiersCheck = pricing.tiers;

  return (
    <div className="bg-gray-50 " id="pricing">
      <div className="isolate overflow-hidden">
        <div className="flow-root bg-white py-16 sm:pt-32 lg:pb-0">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="relative z-10">
              <h1 className="mx-auto max-w-4xl text-center text-5xl font-bold tracking-tight text-gray-900">
                Pricing plans for Companies and Individuals
              </h1>
              <p className="mx-auto mt-4 max-w-2xl text-center text-lg leading-8 text-gray-600">
                Choose an affordable plan that’s packed with the best features
                for engaging your audience, creating customer loyalty, and
                driving sales.
              </p>
              <div className="mt-16 flex justify-center">
                <fieldset aria-label="Payment frequency">
                  <RadioGroup
                    value={frequency}
                    onChange={setFrequency}
                    className="grid grid-cols-2 gap-x-1 rounded-full bg-gray-200 p-1 text-center text-xs font-semibold leading-5 text-gray-900"
                  >
                    {pricing.frequencies.map((option) => (
                      <Radio
                        key={option.value}
                        value={option}
                        className="cursor-pointer rounded-full px-2.5 py-1 data-[checked]:bg-indigo-500 data-[checked]:text-white"
                      >
                        {option.label}
                      </Radio>
                    ))}
                  </RadioGroup>
                </fieldset>
              </div>
            </div>
            <div className="relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-3">
              <svg
                viewBox="0 0 1208 1024"
                aria-hidden="true"
                className="absolute -bottom-48 left-1/2 h-[64rem] -translate-x-1/2 translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] lg:-top-48 lg:bottom-auto lg:translate-y-0"
              >
                <ellipse
                  cx={604}
                  cy={512}
                  rx={604}
                  ry={512}
                  fill="url(#d25c25d4-6d43-4bf9-b9ac-1842a30a4867)"
                />
                <defs>
                  <radialGradient id="d25c25d4-6d43-4bf9-b9ac-1842a30a4867">
                    <stop stopColor="#7775D6" />
                    <stop offset={1} stopColor="#E935C1" />
                  </radialGradient>
                </defs>
              </svg>
              <div
                aria-hidden="true"
                className="hidden lg:absolute lg:inset-x-px lg:bottom-0 lg:top-4 lg:block lg:rounded-t-2xl lg:bg-gray-100 lg:ring-1 lg:ring-gray-300"
              />
              {availableTiers.map((tier) => (
                <div
                  key={tier.id}
                  className={classNames(
                    tier.featured
                      ? "z-10 bg-white shadow-xl ring-1 ring-gray-900/10"
                      : "bg-gray-100 ring-1 ring-gray-300 lg:bg-transparent lg:pb-14 lg:ring-0",
                    "relative rounded-2xl"
                  )}
                >
                  <div className="p-8 lg:pt-12 xl:p-10 xl:pt-14">
                    <h2
                      id={tier.id}
                      className={classNames(
                        tier.featured ? "text-gray-900" : "text-gray-700",
                        "text-sm font-semibold leading-6"
                      )}
                    >
                      {tier.name}
                    </h2>
                    <div className="flex flex-col gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
                      <div className="mt-2 flex items-center gap-x-4">
                        <p
                          className={classNames(
                            tier.featured ? "text-gray-900" : "text-gray-700",
                            "text-4xl font-bold tracking-tight"
                          )}
                        >
                          {tier.price[frequency.value]}
                        </p>
                        <div className="text-sm leading-5">
                          <p
                            className={
                              tier.featured ? "text-gray-900" : "text-gray-700"
                            }
                          >
                            USD
                          </p>
                          <p
                            className={
                              tier.featured ? "text-gray-500" : "text-gray-400"
                            }
                          >{`Billed ${frequency.priceSuffix}`}</p>
                        </div>
                      </div>
                      <a
                        href={tier.href}
                        aria-describedby={tier.id}
                        className={classNames(
                          tier.featured
                            ? "bg-indigo-600 shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600 text-white"
                            : "bg-gray-200 hover:bg-gray-300 focus-visible:outline-gray-200 text-gray-900",
                          "rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        )}
                      >
                        Book a Demo
                      </a>
                    </div>
                    <div className="mt-8 flow-root sm:mt-10">
                      <ul
                        role="list"
                        className={classNames(
                          tier.featured
                            ? "divide-gray-200 border-gray-200 text-gray-600"
                            : "divide-gray-300 border-gray-300 text-gray-700",
                          "-my-2 divide-y border-t text-sm leading-6 lg:border-t-0"
                        )}
                      >
                        {tier.highlights.map((mainFeature) => (
                          <li key={mainFeature} className="flex gap-x-3 py-2">
                            <CheckIcon
                              aria-hidden="true"
                              className={classNames(
                                tier.featured
                                  ? "text-indigo-600"
                                  : "text-gray-500",
                                "h-6 w-5 flex-none"
                              )}
                            />
                            {mainFeature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="relative bg-white lg:pt-14">
          <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
            {/* Feature comparison (up to lg) */}
            <section
              aria-labelledby="mobile-comparison-heading"
              className="lg:hidden"
            >
              <h2 id="mobile-comparison-heading" className="sr-only">
                Feature comparison
              </h2>

              <div className="mx-auto max-w-2xl space-y-16">
                {availableTiers.map((tier) => (
                  <div key={tier.id} className="border-t border-gray-300">
                    <div
                      className={classNames(
                        tier.featured
                          ? "border-indigo-600"
                          : "border-transparent",
                        "-mt-px w-72 border-t-2 pt-10 md:w-80"
                      )}
                    >
                      <h3
                        className={classNames(
                          tier.featured ? "text-indigo-600" : "text-gray-900",
                          "text-sm font-semibold leading-6"
                        )}
                      >
                        {tier.name}
                      </h3>
                      <p className="mt-1 text-sm leading-6 text-gray-600">
                        {tier.description}
                      </p>
                    </div>

                    <div className="mt-10 space-y-10">
                      {pricing.sections.map((section) => (
                        <div key={section.name}>
                          <h4 className="text-sm font-semibold leading-6 text-gray-900">
                            {section.name}
                          </h4>
                          <div className="relative mt-6">
                            {/* Fake card background */}
                            <div
                              aria-hidden="true"
                              className="absolute inset-y-0 right-0 hidden w-1/2 rounded-lg bg-white shadow-sm sm:block"
                            />

                            <div
                              className={classNames(
                                tier.featured
                                  ? "ring-2 ring-indigo-600"
                                  : "ring-1 ring-gray-900/10",
                                "relative rounded-lg bg-white shadow-sm sm:rounded-none sm:bg-transparent sm:shadow-none sm:ring-0"
                              )}
                            >
                              <dl className="divide-y divide-gray-200 text-sm leading-6">
                                {section.features.map((feature) => (
                                  <div
                                    key={feature.name}
                                    className="flex items-center justify-between px-4 py-3 sm:grid sm:grid-cols-2 sm:px-0"
                                  >
                                    <dt className="pr-4 text-gray-600">
                                      {feature.name}
                                    </dt>
                                    <dd className="flex items-center justify-end sm:justify-center sm:px-4">
                                      {typeof feature.tiers[tier.name] ===
                                      "string" ? (
                                        <span
                                          className={
                                            tier.featured
                                              ? "font-semibold text-indigo-600"
                                              : "text-gray-900"
                                          }
                                        >
                                          {feature.tiers[tier.name]}
                                        </span>
                                      ) : (
                                        <>
                                          {feature.tiers[tier.name] === true ? (
                                            <CheckIcon
                                              aria-hidden="true"
                                              className="mx-auto h-5 w-5 text-indigo-600"
                                            />
                                          ) : (
                                            <XMarkIconMini
                                              aria-hidden="true"
                                              className="mx-auto h-5 w-5 text-gray-400"
                                            />
                                          )}

                                          <span className="sr-only">
                                            {feature.tiers[tier.name] === true
                                              ? "Yes"
                                              : "No"}
                                          </span>
                                        </>
                                      )}
                                    </dd>
                                  </div>
                                ))}
                              </dl>
                            </div>

                            {/* Fake card border */}
                            <div
                              aria-hidden="true"
                              className={classNames(
                                tier.featured
                                  ? "ring-2 ring-indigo-600"
                                  : "ring-1 ring-gray-900/10",
                                "pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 rounded-lg sm:block"
                              )}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </section>

            {/* Feature comparison (lg+) */}
            <section
              aria-labelledby="comparison-heading"
              className="hidden lg:block"
            >
              <h2 id="comparison-heading" className="sr-only">
                Feature comparison
              </h2>

              <div className="grid grid-cols-4 gap-x-8 border-t border-gray-300 before:block">
                {availableTiers.map((tier) => (
                  <div key={tier.id} aria-hidden="true" className="-mt-px">
                    <div
                      className={classNames(
                        tier.featured
                          ? "border-indigo-600"
                          : "border-transparent",
                        "border-t-2 pt-10"
                      )}
                    >
                      <p
                        className={classNames(
                          tier.featured ? "text-indigo-600" : "text-gray-900",
                          "text-sm font-semibold leading-6"
                        )}
                      >
                        {tier.name}
                      </p>
                      <p className="mt-1 text-sm leading-6 text-gray-600">
                        {tier.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="-mt-6 space-y-16">
                {pricing.sections.map((section) => (
                  <div key={section.name}>
                    <h3 className="text-sm font-semibold leading-6 text-gray-900">
                      {section.name}
                    </h3>
                    <div className="relative -mx-8 mt-10">
                      {/* Fake card backgrounds */}
                      <div
                        aria-hidden="true"
                        className="absolute inset-x-8 inset-y-0 grid grid-cols-4 gap-x-8 before:block"
                      >
                        {availableTiers.map((tier) => (
                          <div
                            key={tier.id}
                            className="h-full w-full rounded-lg bg-white shadow-sm"
                          />
                        ))}
                      </div>

                      <table className="relative w-full border-separate border-spacing-x-8">
                        <thead>
                          <tr className="text-left">
                            <th scope="col">
                              <span className="sr-only">Feature</span>
                            </th>
                            {availableTiers.map((tier) => (
                              <th key={tier.id} scope="col">
                                <span className="sr-only">
                                  {tier.name} tier
                                </span>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {section.features.map((feature, featureIdx) => (
                            <tr key={feature.name}>
                              <th
                                scope="row"
                                className="w-1/4 py-3 pr-4 text-left text-sm font-normal leading-6 text-gray-900"
                              >
                                {feature.name}
                                {featureIdx !== section.features.length - 1 ? (
                                  <div className="absolute inset-x-8 mt-3 h-px bg-gray-200" />
                                ) : null}
                              </th>
                              {availableTiers.map((tier) => (
                                <td
                                  key={tier.id}
                                  className="relative w-1/4 px-4 py-0 text-center"
                                >
                                  <span className="relative h-full w-full py-3">
                                    {typeof feature.tiers[tier.name] ===
                                    "string" ? (
                                      <span
                                        className={classNames(
                                          tier.featured
                                            ? "font-semibold text-indigo-600"
                                            : "text-gray-900",
                                          "text-sm leading-6"
                                        )}
                                      >
                                        {feature.tiers[tier.name]}
                                      </span>
                                    ) : (
                                      <>
                                        {feature.tiers[tier.name] === true ? (
                                          <CheckIcon
                                            aria-hidden="true"
                                            className="mx-auto h-5 w-5 text-indigo-600"
                                          />
                                        ) : (
                                          <XMarkIconMini
                                            aria-hidden="true"
                                            className="mx-auto h-5 w-5 text-gray-400"
                                          />
                                        )}

                                        <span className="sr-only">
                                          {feature.tiers[tier.name] === true
                                            ? "Yes"
                                            : "No"}
                                        </span>
                                      </>
                                    )}
                                  </span>
                                </td>
                              ))}
                              {availableTiers.length < 3 && (
                                <td className="w-1/4 px-4 py-0 text-center"></td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {/* Fake card borders */}
                      <div
                        aria-hidden="true"
                        className="pointer-events-none absolute inset-x-8 inset-y-0 grid grid-cols-4 gap-x-8 before:block"
                      >
                        {availableTiers.map((tier) => (
                          <div
                            key={tier.id}
                            className={classNames(
                              tier.featured
                                ? "ring-2 ring-indigo-600"
                                : "ring-1 ring-gray-900/10",
                              "rounded-lg"
                            )}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
