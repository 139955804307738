import axios from "axios";

// LOCAL
// const URL = "http://localhost:3000";

// DEV
// const URL = "https://b5wdetzoo3.execute-api.us-west-2.amazonaws.com";

// PROD
const URL = "https://deue89tfs7.execute-api.us-west-2.amazonaws.com/";

const api = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const get = async (url) => {
  const response = await api.get(url);
  return response.data;
};

export const post = async (url, data) => {
  const response = await api.post(url, data);
  return response.data;
};

export const put = async (url, data) => {
  const response = await api.put(url, data);
  return response.data;
};

const API = {
  get,
  post,
  put,
};

export default API;
