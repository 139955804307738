import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// Components
import Header from "../../components/Header";

// API
import { confirmPayment } from "../../utils/ApiUtils";

// Redux
import { setShowErrorModal } from "../../redux/actions";

const PaymentConfirmation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const confirmPaymentInDb = async (email, pk) => {
    try {
      // Call API to process payment
      await confirmPayment({ email, pk });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      const errorModalProps = {
        title: "Error",
        message:
          "We failed to process your payment. If any money was deducted, it will be refunded within next 7 working days. For any queries, please reach out to us at support@knockproapp.com",
        children: null,
      };
      dispatch(setShowErrorModal(true, errorModalProps));
    }
  };

  useEffect(() => {
    if (searchParams) {
      const email = searchParams.get("email");
      const pk = searchParams.get("pk");
      if (email && pk) {
        confirmPaymentInDb(email, pk);
      }
    } else {
      navigate("/fail");
      setIsLoading(false);
    }
  }, [searchParams]);

  const onClickGoHome = () => {
    navigate("/");
  };

  if (isLoading) {
    return (
      <div className="bg-white">
        <Header showHome={true} />
        <div className="bg-gray-100 min-h-screen flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-md max-w-md mx-auto text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent mx-auto mb-4"></div>
            <h2 className="text-lg font-bold mb-2">Processing Payment...</h2>
            <h4 className="text-md font-semi-bold mb-4">
              Do not press back or refresh the page!
            </h4>
          </div>
        </div>
      </div>
    );
  }

  if (hasError) {
    return (
      <div className="bg-white">
        <Header showHome={true} />
        <div className="bg-gray-100 min-h-screen flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-md max-w-md mx-auto text-center">
            <div>
              <div className="bg-red-100 rounded-full p-4 mx-auto mb-4 w-20 h-20 flex items-center justify-center">
                <svg
                  className="w-10 h-10 text-red-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <h2 className="text-2xl font-bold mb-4">Payment Failed</h2>
              <p className="text-gray-600 mb-6">
                Something went wrong with the payment process. Please try again.
              </p>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                onClick={onClickGoHome}
              >
                Go Home
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white">
      <Header showHome={true} />
      <div className="bg-gray-100 min-h-screen flex justify-center items-center">
        <div className="bg-white p-8 rounded-lg shadow-md max-w-md mx-auto text-center">
          <div>
            <div className="bg-green-100 rounded-full p-4 mx-auto mb-4 w-20 h-20 flex items-center justify-center">
              <svg
                className="w-10 h-10 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h2 className="text-2xl font-bold mb-4">Payment Successful</h2>
            <p className="text-gray-600 mb-6">
              Thank you for your payment. Your order has been processed
              successfully.
            </p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              onClick={onClickGoHome}
            >
              Go Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
