import { SET_SHOW_ERROR_MODAL, SET_SHOW_INFO_MODAL } from "./types";

export const setShowInfoModal = (showInfoModal, infoModalProps) => {
  return {
    type: SET_SHOW_INFO_MODAL,
    payload: showInfoModal,
    infoModalProps: infoModalProps,
  };
};

export const setShowErrorModal = (showErrorModal, errorModalProps) => {
  return {
    type: SET_SHOW_ERROR_MODAL,
    payload: showErrorModal,
    errorModalProps: errorModalProps,
  };
};
