import React, { useEffect } from "react";

import { useSearchParams } from "react-router-dom";

const AppRedirect = () => {
  const [searchParams, _] = useSearchParams();
  useEffect(() => {
    if (window.location.hostname === "localhost") {
      window.location.replace(
        `exp://127.0.0.1:8081/--/invite-user?code=${searchParams.get("code")}`
      );
    } else {
      window.location.replace(
        `knockproapp://invite-user?code=${searchParams.get("code")}`
      );
    }
  }, []);
  return <div>Loading...</div>;
};

export default AppRedirect;
