import React from "react";
import {
  ChartBarIcon,
  MapIcon,
  SquaresPlusIcon,
  AcademicCapIcon,
  CalendarIcon,
  UserGroupIcon,
  UserPlusIcon,
  IdentificationIcon,
  ChatBubbleLeftRightIcon
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "KPIs",
    description:
      "Gain valuable insights into your team's performance with customizable KPIs. Track progress, identify areas for improvement, and make data-driven decisions to drive better results.",
    icon: ChartBarIcon,
  },
  {
    name: "Canvassing",
    description:
      "Streamline your canvassing efforts with intelligent territory assignment, optimized routing, and real-time progress tracking, ensuring efficient resource allocation and maximized coverage",
    icon: MapIcon,
  },
  {
    name: "Area Management",
    description:
      "Effectively manage your territories and assignments with our intuitive area management tools. Define boundaries, assign resources, and monitor progress for optimal field operations.",
    icon: SquaresPlusIcon,
  },
  {
    name: "Trainings",
    description:
      "Ensure your team is equipped with the knowledge and skills they need through our comprehensive training management capabilities. Create, assign, and track training modules seamlessly",
    icon: AcademicCapIcon,
  },
  {
    name: "Scheduling",
    description:
      "Optimize your workforce scheduling with our advanced scheduling tools. Easily manage shifts, assignments, and availability, ensuring the right resources are deployed at the right time and place",
    icon: CalendarIcon,
  },
  {
    name: "Rep Management",
    description:
      "Efficiently manage your field representatives with centralized profiles, performance tracking, and communication channels. Foster a productive and engaged team with ease.",
    icon: UserGroupIcon,
  },
  {
    name: "Onboarding",
    description:
      "Streamline the onboarding process for new hires with our user-friendly onboarding tools. Provide a seamless experience and ensure they're ready to hit the ground running.",
    icon: UserPlusIcon,
  },
  {
    name: "Digital Rep Cards",
    description:
      "Empower your team with digital rep cards, providing them with instant access to essential information and resources while on the go, boosting productivity and efficiency.",
    icon: IdentificationIcon,
  },
  {
    name: "Chat",
    description:
      "Facilitate seamless communication with team members and clients through our integrated chat feature, ensuring quick and efficient collaboration.",
    icon: ChatBubbleLeftRightIcon,
  },
];

const Product = () => {
  return (
    <div className="bg-white w-full py-24 sm:py-32" id="product">
      <div className="mx-auto w-full max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            Our Features Explained
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Comprehensive Field Management Solution
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our powerful platform streamlines field operations, empowering your
            team to achieve maximum efficiency and productivity. Explore our
            robust features tailored to meet your unique needs.
          </p>
        </div>
        <div className="mx-auto w-full mt-16 sm:mt-20 lg:mt-24">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative text-center">
                <dt className="flex justify-center">
                  <feature.icon
                    className="h-28 w-28 text-indigo-600"
                    aria-hidden="true"
                  />
                </dt>
                <dt className="mt-4 text-xl font-semibold tracking-tight leading-7 text-gray-900">
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Product;