import React from "react";
import Header from "../../components/Header";
import Pricing from "../Dashboard/components/Pricing";
import Footer from "../Dashboard/components/Footer";

const PricingPage = () => {
  return (
    <div className="bg-white mt-28">
        <Pricing />
    </div>
  );
};

export default PricingPage;