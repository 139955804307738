import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import Header from "../../components/Header";

const PaymentConfirmation = () => {
  const navigate = useNavigate();

  const onClickGoHome = () => {
    navigate("/");
  };

  return (
    <div className="bg-white">
      <Header showHome={true} />
      <div className="bg-gray-100 min-h-screen flex justify-center items-center">
        <div className="bg-white p-8 rounded-lg shadow-md max-w-md mx-auto text-center">
          <div>
            <div className="bg-red-100 rounded-full p-4 mx-auto mb-4 w-20 h-20 flex items-center justify-center">
              <svg
                className="w-10 h-10 text-red-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h2 className="text-2xl font-bold mb-4">Payment Failed</h2>
            <p className="text-gray-600 mb-6">
              Something went wrong with the payment process. Please try again.
            </p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              onClick={onClickGoHome}
            >
              Go Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
