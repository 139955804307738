import React from "react";
import FeaturePage from "./components/FeaturePage";
import Product from "../Dashboard/components/Product";

const ProductPage = () => {
  return (
    <div className="bg-white mt-28 flex flex-col items-center">
      {/* Title Section */}
      <div className="relative w-full " style={{ height: "650px" }}>
        <div
          className="absolute h-full inset-0 bg-cover bg-center"
          style={{ backgroundImage: "url('/assets/featureHeader.png')" }}
        ></div>
        <div className="absolute inset-0 bg-indigo-600 opacity-30"></div>
        <div className="relative z-10 flex flex-col items-start justify-center h-full px-6">
          <div className="max-w-2xl pl-5">
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Comprehensive Field Management Solution
            </p>
            <p className="mt-6 text-lg leading-8 text-white">
              Our powerful platform streamlines field operations, empowering
              your team to achieve maximum efficiency and productivity. Explore
              our robust features tailored to meet your unique needs.
            </p>
          </div>
        </div>
        <svg
          viewBox="0 0 1000 100"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          className="absolute bottom-0 w-full"
        >
          <path
            className="fill-current text-white"
            d="M1000,100H0V80h483l12.8,12.8c2.3,2.3,6.1,2.3,8.5,0L517,80h483V100z"
          ></path>
        </svg>
      </div>

      {/* Container for FeaturePage */}
      <div className="w-10/12 mt-14 max-w-screen-lg border border-gray-300 shadow-lg rounded-lg overflow-hidden mb-8 relative">
        <div
          className="w-full h-0 pb-[56.25%] relative"
          style={{ paddingBottom: "56.25%" }}
        >
          <div className="absolute inset-0">
            <FeaturePage />
          </div>
        </div>
      </div>

      <Product />
    </div>
  );
};

export default ProductPage;