import { SET_SHOW_ERROR_MODAL, SET_SHOW_INFO_MODAL } from "../actions/types";

const initialState = {
  showErrorModal: false,
  errorModalProps: {},
  showInfoModal: false,
  infoModalProps: {},
};

export default function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SHOW_ERROR_MODAL:
      return {
        ...state,
        showErrorModal: action.payload,
        errorModalProps: action.errorModalProps || {},
      };
    case SET_SHOW_INFO_MODAL:
      return {
        ...state,
        showInfoModal: action.payload,
        infoModalProps: action.infoModalProps || {},
      };
    default:
      return state;
  }
}
