import React from "react";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Pages
import Dashboard from "../pages/Dashboard";
import Payment from "../pages/Payment";
import AppRedirect from "../pages/AppRedirect";
import Reps from "../pages/Reps";
import PaymentSuccess from "../pages/PaymentConfirmation/success";
import PaymentFail from "../pages/PaymentConfirmation/fail";
import ErrorPage from "../pages/404";
import PricingPage from "../pages/PricingPage";
import ProductPage from "../pages/ProductPage";
import ContactUsPage from "../pages/ContactUsPage";
import MainLayout from "../components/MainLayout";

// Modals
import InfoModal from "../components/Modals/InfoModal";
import ErrorModal from "../components/Modals/ErrorModal";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const Router = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <MainLayout>
          <Dashboard />
        </MainLayout>
      ),
    },
    {
      path: "payment",
      element: <Payment />,
    },
    {
      path: "success",
      element: <PaymentSuccess />,
    },
    {
      path: "fail",
      element: <PaymentFail />,
    },
    {
      path: "/app-redirect",
      element: <AppRedirect />,
    },
    {
      path: "/reps/:firstName/:lastName/:phone/:email/:companyId/:imgPathname",
      element: <Reps />,
    },
    {
      path: "/product",
      element: (
        <MainLayout>
          <ProductPage />
        </MainLayout>
      ),
    },
    {
      path: "/pricing",
      element: (
        <MainLayout>
          <PricingPage />
        </MainLayout>
      ),
    },
    {
      path: "/contact-us",
      element: (
        <MainLayout>
          <ContactUsPage />
        </MainLayout>
      ),
    },
    {
      path: "/privacy-policy",
      element: (
        <MainLayout>
          <PrivacyPolicy />
        </MainLayout>
      ),
    },
    {
      path: "*",
      element: <ErrorPage />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <InfoModal />
      <ErrorModal />
    </>
  );
};

export default Router;
