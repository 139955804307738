import React from "react";
import { PhoneIcon } from "@heroicons/react/24/outline";

export default function BookADemo() {
  return (
    <div className="relative bg-gray-900" id="bookADemo">
      <div className="relative h-80 overflow-hidden bg-indigo-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
        <img
          className="h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&blend=6366F1&sat=-100&blend-mode=multiply"
          alt=""
        />
        <svg
          viewBox="0 0 926 676"
          aria-hidden="true"
          className="absolute -bottom-24 left-24 w-[57.875rem] transform-gpu blur-[118px]"
        >
          <path
            fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)"
            fillOpacity=".4"
            d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z"
          />
          <defs>
            <linearGradient
              id="60c3c621-93e0-4a09-a0e6-4c228a0116d8"
              x1="926.392"
              x2="-109.635"
              y1=".176"
              y2="321.024"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#776FFF" />
              <stop offset={1} stopColor="#FF4694" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
          <h2 className="text-base font-semibold leading-7 text-indigo-500">
            Get started within 24 hours
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Book A Demo
          </p>
          <p className="mt-6 text-base leading-7 text-gray-300">
            Experience the power of our cutting-edge solution firsthand by
            booking a demo today. Our experts will guide you through a
            personalized demonstration, showcasing how our platform can
            revolutionize your business operations. To book your demo click
            &quot;Book a Demo&quot;. You can also reach out to us via Phone or
            Text message by clicking the respective button below.
          </p>
          <div className="mt-8 flex items-center space-x-4">
            <a
              href="mailto:lauren@knockproapp.com?subject=Book a demo&body=Hi,%0D%0A%0D%0AI would like to book a demo with Knockpro%0D%0A%0D%0APlease find my details below:%0D%0A"
              className="rounded-md bg-white/10 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Book a Demo
            </a>
            <button
              type="button"
              onClick={() => window.open("tel:+1 (214) 592-5950")}
              data-twe-ripple-init
              data-twe-ripple-color="light"
              className="rounded bg-indigo-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <PhoneIcon className="h-4 w-4" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={() =>
                window.open(
                  "sms:+1 (214) 592-5950?body=Hi,%0D%0A%0D%0AI would like to book a demo with Knockpro"
                )
              }
              data-twe-ripple-init
              data-twe-ripple-color="light"
              className="rounded bg-teal-600 px-4 py-1.5 text-sm font-semibold text-white shadow-sm transition duration-150 ease-in-out hover:bg-teal-600/90 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
            >
              <svg
                className="h-6 w-6 text-white-600"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />{" "}
                <line x1="12" y1="11" x2="12" y2="11.01" />{" "}
                <line x1="8" y1="11" x2="8" y2="11.01" />{" "}
                <line x1="16" y1="11" x2="16" y2="11.01" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
