import React, { useState } from "react";

const Footer = () => {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    peopleCount: "Individual",
    hearAboutUs: "Internet Search",
  });

  const handleChange = (event) => {
    const { id, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [id]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const queryParams = new URLSearchParams(form).toString();
    window.location.href = `/signup?${queryParams}`;
  };

  return (
    <footer className="bg-indigo-600" aria-labelledby="footer-heading">
      <div className="relative isolate bg-indigo-600 px-6 py-8 lg:px-8">
        <div className="mx-auto max-w-7xl flex flex-col lg:flex-row">
          {/* First Column */}
          <div className="lg:w-1/2 flex flex-col justify-center p-6">
            <h2 className="text-4xl font-semibold text-white">
              Text your leads faster, consistently connect with your customers,
              and never sound like a bot.
            </h2>
            <p className="mt-4 text-xl text-white">
              Ready to know more about Knockpro? Submit the form and we will
              text you shortly.
            </p>
            {/* Right-aligned arrow image */}
            <div className="mt-4 flex justify-end">
              <img
                src="/assets/arrow_right.webp"
                alt="Arrow"
                className="h-32"
              />
            </div>
          </div>

          {/* Second Column - Form */}
          <div className="lg:w-1/2 p-6 bg-indigo-600 rounded-lg border border-white">
            <form onSubmit={handleSubmit} className="space-y-4 p-4">
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-white"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    value={form.firstName}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-gray-100 text-gray-900 p-3"
                  />
                </div>
                <div className="flex-1">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-white"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    value={form.lastName}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-gray-100 text-gray-900 p-3"
                  />
                </div>
              </div>

              <div className="flex space-x-4">
                <div className="flex-1">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-white"
                  >
                    Phone
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    value={form.phone}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-gray-100 text-gray-900 p-3"
                  />
                </div>
                <div className="flex-1">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-white"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={form.email}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-gray-100 text-gray-900 p-3"
                  />
                </div>
              </div>

              <div className="flex space-x-4">
                <div className="flex-1">
                  <label
                    htmlFor="companyName"
                    className="block text-sm font-medium text-white"
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    id="companyName"
                    value={form.companyName}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-gray-100 text-gray-900 p-3"
                  />
                </div>
                <div className="flex-1">
                  <label
                    htmlFor="peopleCount"
                    className="block text-sm font-medium text-white"
                  >
                    Who will use?
                  </label>
                  <select
                    id="peopleCount"
                    value={form.peopleCount}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-gray-100 text-gray-900 p-3"
                  >
                    <option>Single User</option>
                    <option>Company</option>
                  </select>
                </div>
              </div>

              <div>
                <label
                  htmlFor="hearAboutUs"
                  className="block text-sm font-medium text-white"
                >
                  How did you hear about us?
                </label>
                <select
                  id="hearAboutUs"
                  value={form.hearAboutUs}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-gray-100 text-gray-900 p-3"
                >
                  <option>Internet Search</option>
                  <option>Social Media</option>
                  <option>Word of Mouth</option>
                  <option>Online Advertisement</option>
                  <option>Offline Advertisement</option>
                  <option>Email Marketing</option>
                </select>
              </div>

              <button
                type="submit"
                className="mt-4 w-1/4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign Up
              </button>
            </form>
          </div>
        </div>
        <p className="text-xs leading-5 text-white md:order-1 md:mt-0">
          &copy; 2024 Knockpro, Inc. All rights reserved.{" "}
        </p>

        <a
          className="text-xs  leading-5 text-white md:order-1 md:mt-0"
          href="privacy-policy"
        >
          <strong>Privacy Policy</strong>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
