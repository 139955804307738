import API from "../api";

export const makePayment = async (body) => {
  return API.post(`/stripePayment/makePayment`, body);
};

export const confirmPayment = async (body) => {
  return API.put(`/stripePayment/confirmPayment`, body);
};

export const getProfilePic = async (payload) => {
  return await API.get(
    `/users/profilePicture?imgPathname=${payload.imgPathname}`
  );
};

export const getCompanyLogo = async (payload) => {
  return await API.get(`/company/logo?companyId=${payload.companyId}`);
};
